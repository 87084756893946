import { Text } from '@radix-ui/themes';
import Link from 'next/link';

export default function Custom404() {
  return (
    <div style={{textAlign: "center", paddingTop: '35vh'}}>
      <Text as="div" size={"8"} >
        <h1>404 - Page Not Found</h1></Text>
      <Text as="p">
        Sorry, the page you are looking for does not exist.
      </Text>
      <Link href="/" style={{cursor: 'pointer', textDecoration: "underline"}}>
        <Text>Go back to Home</Text>
      </Link>
    </div>
  );
}